import React, { useContext, useEffect, useRef } from 'react'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import { Offcanvas } from 'react-bootstrap'
import Addmoney from './AddMoney'
import UserContext from '../context/UserContext'
import { useDispatch, useSelector } from 'react-redux'
import { setFilteredInfoData } from '../redux/actions/actions'

export default function Header() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const profileData = useSelector((state) => state.profileData)
  const infoData = useSelector((state) => state.infoData)
  const pathname = useLocation().pathname
  const context = useContext(UserContext)
  const { modal, setModal, showSearch, setShowSearch } = context
  const searchFieldRef = useRef(null)

  const handleLinkClick = () => {
    setShowSearch(!showSearch)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchFieldRef.current && !searchFieldRef.current.contains(event.target)) {
        handleLinkClick()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleLinkClick])

  const signoutHandler = () => {
    setModal({ ...modal, sideNav: false })
    localStorage.removeItem('authToken')
    navigate('/send-otp')
  }

  const onChangeHandler = (e) => {
    const value = e.target.value
    const availableGames = infoData.availableGames?.filter((element) => {
      return element.name.toLowerCase().includes(value.toLowerCase())
    })

    dispatch(setFilteredInfoData({ ...infoData, availableGames }))
  }

  return (
    <>
      <Addmoney modal={modal} setModal={setModal} />

      <header className='bg-white'>
        <nav className='bg-white'>
          <div className='container d-flex align-items-center'>
            <Link to='/' className='navbar-brand'>
              <img src='images/Logo.svg' />
            </Link>
            <div className='ms-auto d-none d-md-block position-relative'>
              <input className='form-control rounded-pill search-field' type='search' placeholder='Search games...' aria-label='Search' onChange={onChangeHandler} />
              <svg className='icon search-icon position-absolute'>
                <use href='#icon_search'></use>
              </svg>
            </div>
            <div className='d-flex ms-auto align-items-center' role='search'>
              {localStorage.getItem('authToken') ? (
                <>
                  <button type='button' className='btn-coin rounded-pill' onClick={() => setModal({ ...modal, addMoney: true })}>
                    <img className='me-1' src='images/coin.svg' alt='coin' />
                    <span>{profileData.wallet?.toFixed(2)}</span>
                    <img className='ms-1' src='images/rounded-plus.svg' alt='plus' />
                  </button>

                  <button type='button' onClick={() => navigate('/profile')} className='btn-coin use-btn rounded-pill ms-md-4 ms-3'>
                    <img src='images/user.svg' alt='user' />
                    <span>{profileData.name?.slice(0, 4)}</span>
                  </button>
                </>
              ) : (
                <Link to='/send-otp' className='btn btn-login rounded-pill fw-500'>
                  Login
                </Link>
              )}

              <div className='country-box ms-md-4 d-none d-lg-block'>
                <button type='button' className='country-btn d-flex align-items-center' data-bs-toggle='collapse' data-bs-target='#collapseCountryList' aria-expanded='false' aria-controls='collapseCountryList'>
                  <img src='images/india-flag.svg' alt='flag' />
                  <span className='mx-1'>IN</span>
                  <svg className='icon'>
                    <use href='#icon_chevrondown'></use>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </nav>
        <div className='navbar navbar-expand-lg bg-white p-0'>
          <div className='container  pb-md-0  pb-2'>
            {pathname === '/' ? (
              <>
                <button className='navbar-toggler back-btn' type='button' onClick={() => setModal({ ...modal, sideNav: true })}>
                  <svg className='icon'>
                    <use href='#icon_menu'></use>
                  </svg>
                </button>

                <button type='button' className='ms-auto btn border-0 p-0 m-0 pt-1 d-flex align-items-center justify-content-center me-3 d-block d-md-none' onClick={handleLinkClick}>
                  <svg className='icon search-icon fs-24'>
                    <use href='#icon_search'></use>
                  </svg>
                </button>

                <div className='country-box navbar-toggler border-0 p-0'>
                  <button type='button' className='country-btn' data-bs-toggle='collapse' data-bs-target='#collapseCountryList' aria-expanded='false' aria-controls='collapseCountryList'>
                    <img src='images/india-flag.svg' alt='flag' />
                    <span className='ms-1'>IN</span>
                  </button>
                </div>
              </>
            ) : (
              <>
                <Link to={-1} className='navbar-toggler back-btn'>
                  <svg className='icon'>
                    <use href='#icon_backarrow'></use>
                  </svg>
                </Link>

                <button className='navbar-toggler back-btn' type='button' onClick={() => setModal({ ...modal, sideNav: true })}>
                  <svg className='icon'>
                    <use href='#icon_menu'></use>
                  </svg>
                </button>
              </>
            )}

            <div className='collapse navbar-collapse ' id='navbarNavAltMarkup'>
              <div className='navbar-nav d-flex justify-content-evenly w-100 fs-16px fw-500'>
                <Link className={`nav-link ${pathname === '/' && 'active'}`} aria-current='page' to='/'>
                  Home
                </Link>
                <Link className={`nav-link ${pathname === '/reports' && 'active'}`} to='/reports?type=purchase'>
                  Orders
                </Link>
                <Link className={`nav-link ${pathname === '/my-wallet' && 'active'}`} to='/my-wallet'>
                  My Wallet
                </Link>
                <Link className={`nav-link ${pathname === '/profile' && 'active'}`} to='/profile'>
                  Profile
                </Link>
                <Link className={`nav-link ${pathname === '/about-us' && 'active'}`} to='/about-us'>
                  About Us
                </Link>
                <Link className={`nav-link ${pathname === '/reseller' && 'active'}`} to='/'>
                  Reseller
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>

      <Offcanvas show={modal.sideNav} onHide={() => setModal({ ...modal, sideNav: false })} className='offcanvas offcanvas-start' tabindex='-1' id='offcanvasExample' aria-labelledby='offcanvasExampleLabel'>
        <div className='offcanvas-header'>
          <div className='offcanvas-logo'>
            <Link to='/' className='w-100'>
              <img src='images/logo.png' />
            </Link>
          </div>
          <button type='button' className='btn-close' data-bs-dismiss='offcanvas' aria-label='Close' onClick={() => setModal({ ...modal, sideNav: false })}></button>
        </div>
        <div className='offcanvas-body position-relative'>
          <ul className='offcanvas-aside'>
            <li>
              <NavLink className='nav-link' to='/profile'>
                <span>
                  <svg className='icon'>
                    <use href='#icon_profile'></use>
                  </svg>
                </span>
                <strong>Profile</strong>
                <svg className='icon ms-auto'>
                  <use href='#icon_rightarrow'></use>
                </svg>
              </NavLink>
            </li>
            <li>
              <NavLink className='nav-link' to='/reports?type=purchase'>
                <span>
                  <svg className='icon'>
                    <use href='#icon_order'></use>
                  </svg>
                </span>
                <strong>Orders</strong>
                <svg className='icon ms-auto'>
                  <use href='#icon_rightarrow'></use>
                </svg>
              </NavLink>
            </li>
            <li>
              <NavLink className='nav-link' to='/my-wallet'>
                <span>
                  <svg className='icon'>
                    <use href='#icon_wallet'></use>
                  </svg>
                </span>
                <strong>My Wallet</strong>
                <svg className='icon ms-auto'>
                  <use href='#icon_rightarrow'></use>
                </svg>
              </NavLink>
            </li>
            <li>
              <NavLink className='nav-link' to='/'>
                <span>
                  <svg className='icon'>
                    <use href='#icon_chat'></use>
                  </svg>
                </span>
                <strong>Help & Support</strong>
                <svg className='icon ms-auto'>
                  <use href='#icon_rightarrow'></use>
                </svg>
              </NavLink>
            </li>
            <li>
              <NavLink className='nav-link' to='/'>
                <span>
                  <svg className='icon'>
                    <use href='#icon_handshake'></use>
                  </svg>
                </span>
                <strong>Reseller</strong>
                <svg className='icon ms-auto'>
                  <use href='#icon_rightarrow'></use>
                </svg>
              </NavLink>
            </li>
            <li>
              <NavLink className='nav-link' to='/about-us'>
                <span>
                  <svg className='icon'>
                    <use href='#icon_info'></use>
                  </svg>
                </span>
                <strong>About Us</strong>
                <svg className='icon ms-auto'>
                  <use href='#icon_rightarrow'></use>
                </svg>
              </NavLink>
            </li>
            <li>
              <NavLink className='nav-link' to='/'>
                <span>
                  <svg className='icon'>
                    <use href='#icon_share'></use>
                  </svg>
                </span>
                <strong>Share</strong>
                <svg className='icon ms-auto'>
                  <use href='#icon_rightarrow'></use>
                </svg>
              </NavLink>
            </li>
          </ul>
          {localStorage.getItem('authToken') && (
            <button type='button' className='btn rounded-pill' onClick={signoutHandler}>
              <svg className='icon me-1'>
                <use href='#icon_logout'></use>
              </svg>
              Log out
            </button>
          )}
        </div>
      </Offcanvas>

      <div className='language-dropdown dropdownList collapse' id='collapseCountryList'>
        <div className='offcanvas-header d-flex'>
          <button type='button' className='btn-close ms-auto py-3 my-1 me-3' data-bs-toggle='collapse' data-bs-target='#collapseCountryList' aria-expanded='false' aria-controls='collapseCountryList' aria-label='Close'></button>
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h5>Select your country</h5>
            </div>
            <div className='col-md-6 country-list' id='columnslist'>
              <ul className='offcanvas-right'>
                <li>
                  <Link to='/' className='nav-link'>
                    <span>
                      <img src='images/india-flag.svg' alt='flag' />
                    </span>
                    <strong>India</strong>
                  </Link>
                </li>
                <li>
                  <Link to='/' className='nav-link'>
                    <span>
                      <img src='images/india-flag.svg' alt='flag' />
                    </span>
                    <strong>India</strong>
                  </Link>
                </li>
                <li>
                  <Link to='/' className='nav-link'>
                    <span>
                      <img src='images/india-flag.svg' alt='flag' />
                    </span>
                    <strong>India</strong>
                  </Link>
                </li>
                <li>
                  <Link to='/' className='nav-link'>
                    <span>
                      <img src='images/india-flag.svg' alt='flag' />
                    </span>
                    <strong>India</strong>
                  </Link>
                </li>
                <li>
                  <Link to='/' className='nav-link'>
                    <span>
                      <img src='images/india-flag.svg' alt='flag' />
                    </span>
                    <strong>India</strong>
                  </Link>
                </li>
                <li>
                  <Link to='/' className='nav-link'>
                    <span>
                      <img src='images/india-flag.svg' alt='flag' />
                    </span>
                    <strong>India</strong>
                  </Link>
                </li>
                <li>
                  <Link to='/' className='nav-link'>
                    <span>
                      <img src='images/india-flag.svg' alt='flag' />
                    </span>
                    <strong>India</strong>
                  </Link>
                </li>
                <li>
                  <Link to='/' className='nav-link'>
                    <span>
                      <img src='images/india-flag.svg' alt='flag' />
                    </span>
                    <strong>India</strong>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className='lanlist-overlay' data-bs-toggle='collapse' data-bs-target='#collapseCountryList' aria-expanded='false' aria-controls='collapseCountryList'></div>

      <div className='SearchList-overlay' data-bs-toggle='collapse' data-bs-target='#collapseSearchList' aria-expanded='false' aria-controls='collapseSearchList'></div>

      {showSearch && (
        <div ref={searchFieldRef} className='mobile-searchfield p-3' id='collapseSearchListl'>
          <div className='position-relative w-100'>
            <input className='form-control rounded-pill mobile-search-field w-100' type='text' placeholder='Search games...' onChange={onChangeHandler} />
            <svg className='icon search-icon position-absolute'>
              <use href='#icon_search'></use>
            </svg>
          </div>
        </div>
      )}
    </>
  )
}
