import React, { useEffect } from 'react'
import BottomMenu from '../components/BottomMenu'
import ContactUs from '../components/ContactUs'

export default function PrivacyPolicy() {
  useEffect(() => {
    document.title = 'Demon Official Store - Privacy Policy'
  }, [])

  return (
    <section className='wrapper'>
      <div className='container terms-content'>
        <div className='row pt-md-4 pt-3'>
          <div className='col-12 pb-md-4 pb-md-3 pb-2 pt-3'>
            <h2 className='TermsConditions-Heading pb-md-4 pb-2'>Privacy Policy</h2>

            <p className='terms-paragraph pb-md-4 pb-3'>
              Demon Official Store recognizes that your privacy is very important, so we take all possible measures to protect it. This present policy applies to all services offered by Demon Official Store, including this website. The purpose of
              this privacy policy is to make it clear to users what types of data are collected while browsing our platform and how this data is disclosed to third parties. By accessing and using the Demon Official Store website, you agree with the
              terms of the privacy policy. If you do not agree with the terms, we kindly ask you not to use our services. If you are under 18 years old, it is necessary to read it together with a legal guardian to accept the terms.
            </p>

            <h3 className='Chapter-Heading pb-md-4 pb-2 ps-2'>1. Collected Information</h3>

            <ul className='sub-heading p-0 ps-4 ms-md-3 ms-2'>
              <li>Personal Information</li>
            </ul>

            <p className='terms-paragraph pb-md-4 pb-3'>
              By using our websites, carrying out surveys and filling out registration forms, users agree with Demon Official Store's collection and processing of “personal information”, which will be provided when performing top-ups in the chosen
              games or when using support. Your personal information will only be collected through online forms in secure environments, letting you know when the collection is done.
            </p>

            <ul className='sub-heading p-0 ps-4 ms-md-3 ms-2'>
              <li>Personally Identifiable Information</li>
            </ul>

            <p className='terms-paragraph pb-md-4 pb-3'>
              At times you may be asked to provide personally identifiable information while using the services or when support needs to resolve a user-initiated issue. As the name implies, this is information that allows you to identify the user,
              therefore, for us, your security is very important.
            </p>

            <ul className='sub-heading p-0 ps-4 ms-md-3 ms-2'>
              <li>Non-personal Information</li>
            </ul>

            <p className='terms-paragraph pb-md-4 pb-3'>
              When using Demon Official Store's website and services, it is possible that we collect non-personal information. As the name implies, these are data that do not identify the person and do not allow contact, such as age, gender,
              education, etc. Non-personal information may also include technical data such as your IP address, type of browser used, websites you visit immediately before and after visiting our pages, and other anonymities involving your use of our
              websites. Non-personal information may also include data that you provide to us through your use of our website.
            </p>

            <ul className='sub-heading p-0 ps-4 ms-md-3 ms-2'>
              <li>Online Purchases</li>
            </ul>

            <p className='terms-paragraph pb-md-4 pb-3'>
              When you top up on our website, we collect some personal information. The secure environment of our website is indicated by the padlock on the browser. For example, when performing the recharge, we ask you to provide data to complete
              the order. In addition, about your purchases is stored for us to use when providing support. Your financial information is not stored.
            </p>

            <ul className='sub-heading p-0 ps-4 ms-md-3 ms-2'>
              <li>Cookies</li>
            </ul>

            <p className='terms-paragraph pb-md-4 pb-3'>
              As with many other websites, we use “cookies” to improve the user experience when visiting our websites. When visiting our website, a cookie is inserted in your browser to identify how many times you return to our address. Some
              information collected is IP address, referral source, geographic location, among others. Your personal information is not collected by cookies. These cookies are used to personalize the content offered to users by our website. Most
              browsers have the function of notifying you when a cookie is being introduced on your computer, with you having the option to allow or block it. If you have any questions about how to use cookies, check your browser's Help.
            </p>

            <ul className='sub-heading p-0 ps-4 ms-md-3 ms-2'>
              <li>Cookies</li>
            </ul>

            <p className='terms-paragraph pb-md-4 pb-3'>
              As with many other websites, we use “cookies” to improve the user experience when visiting our websites. When visiting our website, a cookie is inserted in your browser to identify how many times you return to our address. Some
              information collected is IP address, referral source, geographic location, among others. Your personal information is not collected by cookies. These cookies are used to personalize the content offered to users by our website. Most
              browsers have the function of notifying you when a cookie is being introduced on your computer, with you having the option to allow or block it. If you have any questions about how to use cookies, check your browser's Help.
            </p>

            <h3 className='Chapter-Heading pb-md-4 pb-2 ps-2'>2. How We Use Collected Information</h3>

            <ul className='sub-heading p-0 ps-4 ms-md-3 ms-2'>
              <li>Our Services and websites</li>
            </ul>

            <p className='terms-paragraph pb-md-4 pb-3'>
              Your personal information is used to provide services you request, to communicate with you and to make purchases. We use some of this information to understand the user profile that accesses our website and uses our services, such as
              geographic distribution, age group, etc. This information helps us understand and plan better actions, promoting a better experience for users, in addition to also using this information to detect fraud or abuse.
            </p>

            <ul className='sub-heading p-0 ps-4 ms-md-3 ms-2'>
              <li>Email Communications And Online services</li>
            </ul>

            <p className='terms-paragraph pb-md-4 pb-3'>
              When making a purchase through our website in a secure environment, some information provided may be used to send notifications regarding the transaction, as well as updates or news about our services. In that case, we will provide you
              with the option to change your preferences and opt out of receiving such communications. This data is used during the procedure only to process the specific order (including the Credit Card number). These personal data stored are Name,
              User ID, Server ID, Order, WhatsApp Number, and Email. This information may be used by support to resolve any issues the user may encounter.
            </p>

            <h3 className='Chapter-Heading pb-md-4 pb-2 ps-2'>3. Disclosure of Information to Third Parties</h3>

            <p className='terms-paragraph pb-md-4 pb-3'>
              Except as specifically provided in this Policy, we do not share your personal information with any third party without your permission. At no time is the data transferred to agencies or companies for marketing or commercial purposes. We
              may disclose aggregated information such as demographic data and our statistical analyzes to third parties to enable them to perform their technical, analytical, logistical or other functions/tasks on our behalf. In no event does this
              aggregated information include your personal information. Our websites may contain links to other websites. We have no control over the privacy practices or content of any of our partners (commercial or content), sponsors, or other
              sites we link to from our pages. You should check the applicable privacy policy on the third party website after clicking on the link that takes you there. In our communications, both on the websites and by e-mail, it is possible to
              disclose offers and messages from Demon Official Store partners. At no time was your demographic information shared with partners. All public selection control and sending of this type of communication is done by our team. Demon
              Official Store is under no obligation to keep private the personally identifiable information that a user makes available to other users through the software made available by the company, in a chat context with one or several players
              or in other public activities. Your personal information is not shared with any third party without your prior authorization. At no time is your data provided to any company for commercial or marketing purposes. The only information
              that may be provided is non-personal information such as demographic data and statistical analysis so that third parties can perform technical functions on our behalf. Your personal information will not be included under any
              circumstances. Demon Official Store is under no obligation to keep private the personally identifiable information that a user makes available to other users through the software made available by the company, in a chat context with one
              or several players or in other public activities. such as demographic data and statistical analysis so that third parties can perform technical functions on our behalf. Your personal information will not be included under any
              circumstances. Demon Official Store is under no obligation to keep private the personally identifiable information that a user makes available to other users through the software made available by the company, in a chat context with one
              or several players or in other public activities. such as demographic data and statistical analysis so that third parties can perform technical functions on our behalf. Your personal information will not be included under any
              circumstances. Demon Official Storeis under no obligation to keep private the personally identifiable information that a user makes available to other users through the software made available by the company, in a chat context with one
              or several players or in other public activities.
            </p>

            <ul className='sub-heading p-0 ps-4 ms-md-3 ms-2'>
              <li>Service Providers</li>
            </ul>

            <p className='terms-paragraph pb-md-4 pb-3'>
              From time to time, we may hire unaffiliated companies to help us provide you with some type of service. If so, you may need to share your personal information with them. We require these companies to use your data only to provide the
              specific service and we do not authorize them to use or store your information for any other purpose.
            </p>

            <h3 className='Chapter-Heading pb-md-4 pb-2 ps-2'>4. Security of Collected Information</h3>

            <p className='terms-paragraph pb-md-4 pb-3'>
              The security of your personal information is very important to us. For that reason, all your transactions are done in a secure environment. Your financial details are not stored in our database. Even with all of our security measures in
              place to protect your information, no data transmission over the internet is 100% secure. Demon Official Store undertakes to take the necessary precautions to prevent information leakage, guaranteeing its security. If you have any
              questions about security on our website and how we protect your data, please email our support via customer service or our Livechat on whatsapp.
            </p>

            <h3 className='Chapter-Heading pb-md-4 pb-2 ps-2'>5. Corrections, Updates, and Removal of Personally Identifiable Information.</h3>

            <p className='terms-paragraph pb-md-4 pb-3'>
              If your personally identifiable information stored by Demon Official Store contains an error that you need to correct, need to update, add, or you are simply concerned and want it removed from our records, please contact our support
              team on whatsapp. There is the possibility of refusing requests that are unjustifiably repetitive, require disproportionate technical effort, risk the privacy of others, do not observe the requirements for confirmation of ownership
              (sending of documents) or are extremely impractical. Demon Official Store reserves the right not to make any changes to personal information and/or proceed with any action, without the user proving ownership of the registration,
              presenting the documents requested. Demon Official Store may make personally identifiable information available to comply with court orders or laws that require disclosure of such information. In the event of a reorganization, sale or
              merger, we may transfer your personally identifiable information to the relevant third parties with your consent, if required by law.
            </p>

            <h3 className='Chapter-Heading pb-md-4 pb-2 ps-2'>6. Accuracy of Collected Information</h3>

            <p className='terms-paragraph pb-md-4 pb-3'>
              If you have any questions about our Privacy and Cookies Policy, the use of your personal information, accuracy, or any other requests for corrections, please contact us to our Livechat on whatsapp. Note that these fixes cannot change
              your ownership. After receiving the request, we will make the changes so that the information contained in our database is correct and updated. However, we reserve the right to keep information about removals or changes made to the
              database in our files. That way we can use the information to solve future problems.
            </p>

            <h3 className='Chapter-Heading pb-md-4 pb-2 ps-2'>7. Changes to our Privacy Policy</h3>

            <p className='terms-paragraph pb-md-4 pb-3'>
              We reserve the right to change this Policy at any time and will post any changes as soon as they become effective. Please check this Policy regularly to be aware of any changes. We reserve the right to change this Policy at any time. As
              soon as any changes are made, we will inform users through our information channels. It is advisable to regularly consult this policy for possible updates.
            </p>
          </div>
        </div>

        <ContactUs />
        <BottomMenu />
      </div>
    </section>
  )
}
