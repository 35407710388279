import { Link, useLocation, useNavigate } from 'react-router-dom'
import React, { useEffect, useState, createRef } from 'react'
import { verifyotpSchema } from '../../models'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { useFormik } from 'formik'
import CryptoJS from 'crypto-js'
import Swal from 'sweetalert2'

const initialValues = {
  otp: '',
}

export default function Verifyotp() {
  const [otp, setOtp] = useState(Array(6).fill(''))
  const otpInputRefs = Array(6)
    .fill(0)
    .map((_) => createRef())

  const navigate = useNavigate()
  const location = useLocation()
  const numericRegex = /[^0-9]/
  const [name, setName] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    document.title = 'Demon Official Store - Verify'
    if (localStorage.getItem('authToken')) {
      navigate('/')
    } else if (location?.state?.number) {
      fetchNumberInfo(location?.state?.number)
    } else {
      navigate('/send-otp')
    }
    // eslint-disable-next-line
  }, [])

  const { values, setValues, errors, touched, handleBlur, handleChange, handleSubmit, isSubmitting } = useFormik({
    initialValues,
    validationSchema: verifyotpSchema,
    onSubmit: async (values) => {
      try {
        const url = `${process.env.REACT_APP_API_ROOT_URL}auth/verifyOTP`
        const hash = CryptoJS.AES.encrypt(String(location?.state?.number), process.env.REACT_APP_HASH_SECRET).toString()
        const secret = CryptoJS.AES.encrypt(String(values.otp), process.env.REACT_APP_HASH_SECRET).toString()
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          // body: JSON.stringify({
          //   ...values,
          //   number: location?.state?.number,
          // }),
          body: JSON.stringify({ hash, secret }),
        })
        const result = await response.json()
        if (result.success) {
          localStorage.setItem('authToken', result.authToken)
          if (result.register)
            navigate('/register', {
              replace: false,
              state: {
                name: name,
                number: location?.state?.number,
                refercode: location?.state?.refercode,
              },
            })
          else navigate('/')
        } else {
          Swal.fire({
            icon: 'error',
            title: '',
            text: result.msg,
            footer: 'Alert by the AllRecharge team',
            confirmButtonColor: '#008ad8',
          })
        }
      } catch (error) {
        console.log(error)
        Swal.fire({
          icon: 'error',
          title: '',
          text: 'Something went wrong, please try again leter!',
          footer: 'Alert by the AllRecharge team',
          confirmButtonColor: '#008ad8',
        })
      }
    },
  })

  const fetchNumberInfo = async (number) => {
    try {
      const url = `${process.env.REACT_APP_API_ROOT_URL}info/number`
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ number }),
      })
      const result = await response.json()
      if (result.success) {
        setName(result.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const reSendOtp = async () => {
    try {
      const url = `${process.env.REACT_APP_API_ROOT_URL}auth/sendotp`
      const hash = CryptoJS.AES.encrypt(String(location?.state?.number), process.env.REACT_APP_HASH_SECRET).toString()

      setLoading(true)
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ countryCode: location?.state?.countryCode, country: location?.state?.country, hash }),
      })
      const result = await response.json()
      setLoading(false)
      if (result.success) {
        Swal.fire({
          icon: 'success',
          title: '',
          text: result.msg,
          footer: 'Alert by the AllRecharge team',
          confirmButtonColor: '#008ad8',
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: '',
          text: result.msg,
          footer: 'Alert by the AllRecharge team',
          confirmButtonColor: '#008ad8',
        })
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
      Swal.fire({
        icon: 'error',
        title: '',
        text: 'Something went wrong, please try again leter!',
        footer: 'Alert by the AllRecharge team',
        confirmButtonColor: '#008ad8',
      })
    }
  }

  const handleInputChange = (e, index) => {
    const value = e.target.value
    const key = e.key

    if (key === 'Backspace' && !value && index > 0) {
      otpInputRefs[index - 1].current.focus()
      return
    }

    if (value && !/^[0-9]$/.test(value)) {
      return
    }

    const otpArr = [...otp.slice(0, index), value, ...otp.slice(index + 1)]
    setOtp(otpArr)
    const otpValue = otpArr.map((element) => element).join('')
    setValues({ ...values, otp: otpValue })

    if (value && index < otpInputRefs.length - 1) {
      otpInputRefs[index + 1].current.focus()
    }

    otpValue.length === 6 && !isSubmitting && handleSubmit()
  }

  return (
    <>
      <div className='mainheaderFooter'>
        <Header />
      </div>
      <div className='mobile-wrapper'>
        <div className='nav-mobile'>
          <div className='container'>
            <div className='row'>
              <div className='col-12 d-flex align-items-center'>
                <Link to={-1} className='login-back' type='button' aria-label='back navigation'>
                  <svg className='icon'>
                    <use href='#icon_backarrow'></use>
                  </svg>
                </Link>

                <Link to='/' className='mobile-logo mx-auto'>
                  <img src='images/logo.png' />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <section className='wrapper wrapper-login'>
          <form className='login-form' autoComplete='off' onSubmit={handleSubmit}>
            <h2 className='text-center'>Verify Your Account</h2>
            <h6 className='text-center'>
              Please enter the 6-digit OTP sent to <span className='fw-bold'>{location?.state?.preview}</span> to verify your account
            </h6>

            <div className='otp-input-fields'>
              {otp.map((digit, index) => (
                <input
                  key={index}
                  type='text'
                  maxLength={1}
                  inputMode='numeric'
                  name='otp'
                  autoComplete='off'
                  value={digit}
                  onChange={(e) => handleInputChange(e, index)}
                  onKeyDown={(e) => handleInputChange(e, index)}
                  onBlur={handleBlur}
                  ref={otpInputRefs[index]}
                  className={`form-control ${!!errors.otp && touched.otp && 'is-invalid'}`}
                />
              ))}
            </div>

            <button type='submit' disabled={isSubmitting} className='btn btn-pay w-100'>
              {isSubmitting ? (
                <>
                  <span className='spinner-border spinner-border-sm me-05' role='status' aria-hidden='true' />
                  &nbsp;Loading...
                </>
              ) : (
                'Verify'
              )}
            </button>

            <p className='text-center'>
              {loading ? (
                'Loading...'
              ) : (
                <>
                  Didn't receive the OTP? <span onClick={reSendOtp} className='pe-auto' style={{color: "#1A87D9", cursor: "pointer"}}>Resend</span>
                </>
              )}
            </p>
          </form>
        </section>

        <div className='footer-mobile'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <div className='d-flex align-items-center justify-content-between terms-row'>
                  <Link to='/terms-conditions'>Terms and Conditions</Link>
                  <Link to='/privacy-policy'>Privacy Policy</Link>
                  <Link to='/refund-policy'>Refund Policy</Link>
                </div>

                <div className='d-flex align-items-center justify-content-center copy-right'>
                  Copyright © 2024 <Link to='/'>&nbsp; Demon Official Store</Link>. All Rights Reserved.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='mainheaderFooter'>
        <Footer />
      </div>
    </>
  )
}
