import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { Link, useNavigate } from 'react-router-dom'
import { sendotpSchema } from '../../models'
import { useFormik } from 'formik'
import CryptoJS from 'crypto-js'
import Swal from 'sweetalert2'
import { parsePhoneNumber } from 'libphonenumber-js'

const initialValues = {
  preview: '',
  countryCode: '',
  country: '',
  number: '',
}

export default function Sendotp() {
  const navigate = useNavigate()

  useEffect(() => {
    document.title = 'Demon Official Store - Send OTP'

    if (localStorage.getItem('authToken')) {
      navigate('/')
    }
  }, [])

  const { values, setValues, errors, touched, handleBlur, handleChange, handleSubmit, isSubmitting } = useFormik({
    initialValues,
    validationSchema: sendotpSchema,
    onSubmit: async (values) => {
      try {
        const url = `${process.env.REACT_APP_API_ROOT_URL}auth/sendotp`
        const hash = CryptoJS.AES.encrypt(String(values.number), process.env.REACT_APP_HASH_SECRET).toString()

        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ countryCode: values.countryCode, country: values.country, hash }),
        })
        const result = await response.json()
        if (result.success) {
          navigate('/verify-otp', {
            replace: false,
            state: values,
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: '',
            text: result.msg,
            footer: 'Alert by the AllRecharge team',
            confirmButtonColor: '#008ad8',
          })
        }
      } catch (error) {
        console.log(error)
        Swal.fire({
          icon: 'error',
          title: '',
          text: 'Something went wrong, please try again later!',
          footer: 'Alert by the AllRecharge team',
          confirmButtonColor: '#008ad8',
        })
      }
    },
  })

  const handlePhoneChange = (value) => {
    setValues({ ...values, preview: value })

    if (value.length > 5) {
      const parsedNumber = parsePhoneNumber(value)
      if (parsedNumber) {
        setValues({
          ...values,
          preview: value,
          countryCode: '+' + parsedNumber.countryCallingCode,
          country: parsedNumber.country,
          number: parsedNumber.nationalNumber,
        })
      }
    }
  }

  return (
    <>
      <div className='mainheaderFooter'>
        <Header />
      </div>
      <div className='mobile-wrapper'>
        <div className='nav-mobile'>
          <div className='container'>
            <div className='row'>
              <div className='col-12 d-flex align-items-center'>
                <Link to={-1} className='login-back' type='button' aria-label='back navigation'>
                  <svg className='icon'>
                    <use href='#icon_backarrow'></use>
                  </svg>
                </Link>

                <Link to='/' className='mobile-logo mx-auto'>
                  <img src='images/logo.png' />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <section className='wrapper-login'>
          <form className='login-form' autoComplete='off' onSubmit={handleSubmit}>
            <h2 className='text-center'>Welcome!</h2>
            <h6 className='text-center'>Enter your phone number</h6>

            <div className=''>
              <PhoneInput
                international
                defaultCountry='IN'
                autoComplete='off'
                name='number'
                value={values.preview}
                onChange={handlePhoneChange}
                onBlur={(e) => {
                  handleBlur(e)
                  values.number.toString().length === 10 && !isSubmitting && handleSubmit()
                }}
                className={`form-control p-0 border-0 input-box ${!!errors.number && touched.number && 'is-invalid border-1'}`}
                // class='form-control form-control-lg input-box'
              />
              {errors.number && touched.number && <div className='invalid-feedback'>{errors.number}</div>}
            </div>

            <button className='btn btn-pay w-100' type='submit' disabled={isSubmitting}>
              {isSubmitting ? (
                <>
                  <span className='spinner-border spinner-border-sm me-05' role='status' aria-hidden='true' />
                  &nbsp;Loading...
                </>
              ) : (
                'Send OTP'
              )}
            </button>

            {/* <h5 class='right-line left-line center-text'>Or continue with </h5>
            <div className='login-social'>
              <a href='/' class='btn btn-social'>
                <img src='images/google.svg' alt='google' />
              </a>
              <a href='/' class='btn btn-social fb-btn'>
                <img src='images/fb.svg' alt='facebook' />
              </a>
              <a href='/' class='btn btn-social'>
                <img src='images/apple.svg' alt='apple' />
              </a>
            </div> */}
          </form>
        </section>

        <div className='footer-mobile'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <div className='d-flex align-items-center justify-content-between terms-row'>
                  <Link to='/terms-conditions'>Terms and Conditions</Link>
                  <Link to='/privacy-policy'>Privacy Policy</Link>
                  <Link to='/refund-policy'>Refund Policy</Link>
                </div>

                <div className='d-flex align-items-center justify-content-center copy-right'>
                  Copyright © 2024 <Link to='/'>&nbsp; Demon Official Store</Link>. All Rights Reserved.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='mainheaderFooter'>
        <Footer />
      </div>
    </>
  )
}
