export const setProfileData = (data) => {
    return {
        type: 'setProfileData',
        payload: data
    }
}

export const setInfoData = (data) => {
    return {
        type: 'setInfoData',
        payload: data
    }
}

export const setFilteredInfoData = (data) => {
    return {
        type: 'setFilteredInfoData',
        payload: data
    }
}