import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <footer className='pt-5 pb-3'>
      <div className='container'>
        <div className='row g-4 pb-4' id="accordionFooter">
          <div className='col-lg-3 col-sm-6'>
            <h6 class="collapse-mobile" data-bs-toggle="collapse" data-bs-target="#box1">Our Company</h6>
            <ul class="collapse" id="box1" data-bs-parent="#accordionFooter">
              <li>
                <Link to='/terms-conditions'>Terms and Conditions</Link>
              </li>
              <li>
                <Link to='/privacy-policy'>Privacy Policy</Link>
              </li>
              <li>
                <Link to='/refund-policy'>Refund Policy</Link>
              </li>
              <li>
                <Link to='/about-us'>About Us</Link>
              </li>
            </ul>
          </div>
          <div className='col-lg-3 col-sm-6'>
            <h6 class="collapse-mobile" data-bs-toggle="collapse" data-bs-target="#box2">Catagory</h6>
            <ul class="collapse" id="box2" data-bs-parent="#accordionFooter">
              <li>
                <Link to='/mobile-legends'>Popular Games</Link>
              </li>
              <li>
                <Link to='/'>Rewards Center</Link>
              </li>
              <li>
                <Link to='/profile'>My Account</Link>
              </li>
              <li>
                <Link to='/'>Reseller</Link>
              </li>
            </ul>
          </div>
          <div className='col-lg-3 col-sm-6'>
            <h6 class="collapse-mobile" data-bs-toggle="collapse" data-bs-target="#box3">Support</h6>
            <ul class="collapse" id="box3" data-bs-parent="#accordionFooter">
              <li>
                <a href='mailto:admin@demonofficialstore.com'>admin@demonofficialstore.com</a>
              </li>
            </ul>
          </div>
          <div className='col-lg-3 col-sm-6 d-flex'>
            <div className='ms-md-auto'>
              <h6>Download the App</h6>

              <div className='d-none d-lg-block'>
                <div className='download-app d-flex align-items-center justify-content-around '>
                  <Link to='#' target='_blank' className='white-box'></Link>
                  <Link to='#' target='_blank' className='game-box'>
                    <img src='images/game.svg' alt='game' />
                  </Link>
                </div>
              </div>

              <div className='d-flex gap-4 d-block d-lg-none pb-2'>
                <button type='button' className='btn border-0 p-0 m-0'>
                  <img className='w-100' src='images/playstore.svg' alt='playstore' />
                </button>

                <button type='button' className='btn border-0 p-0 m-0'>
                  <img className='w-100 h-100' src='images/app-store.svg' alt='app store' />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className='row row-cols-1 row-cols-md-2 g-4 pb-4'>
          <div className='col'>
            <div className='navbar-brand'>
              <Link to='/'>
                <img src='images/Logo.svg' />
              </Link>
            </div>
          </div>
          <div className='col d-flex footer-socialicon'>
            <div className='ms-md-auto'>
              <h5 className='text-md-center pb-2'>Follow us on :</h5>
              <div className='d-flex align-items-center justify-content-between'>
                <a href='https://www.facebook.com/demonofficialstore?mibextid=ZbWKwL' target='_blank' className='facebook'>
                  <img src='images/facebook.svg' alt='facebook' />
                </a>
                <a href='https://instagram.com/demonofficialstore?igshid=ZGUzMzM3NWJiOQ==' target='_blank' className='instagram'>
                  <img src='images/instagram.svg' alt='instagram' />
                </a>
                <a href='https://www.youtube.com/@DemonOfficial_' target='_blank' className='youtube'>
                  <img src='images/youtube.png' alt='youtube' />
                </a>
                <a href='https://t.me/thedemonofficial' target='_blank' className='telegram'>
                  <img src='images/telegram.svg' alt='telegram' />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-center align-items-center copyright pt-2'>Copyright © 2024 Demon Official Store. All Rights Reserved.</div>
      </div>
    </footer>
  )
}
