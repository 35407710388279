import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import BottomMenu from '../components/BottomMenu'
import ContactUs from '../components/ContactUs'

export default function Home() {
  const navigate = useNavigate()
  const infoData = useSelector((state) => state.infoData)
  const filteredInfoData = useSelector((state) => state.filteredInfoData)

  useEffect(() => {
    document.title = 'Demon Official Store - Home'
  }, [])

  const settings = {
    dots: true,
    centerMode: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    slidesToShow: 1,
    swipe: true,
    swipeToSlide: true,
    centerPadding: '160px',
    slidesToScroll: 1,
    padding: '0',
    slideMargin: '0',
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          centerPadding: '40px',
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          centerPadding: '0px',
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
          centerPadding: '0px',
          slidesToScroll: 1,
        },
      },
    ],
  }

  // animation-element slide-left
  const [inView, setInView] = useState(false)

  useEffect(() => {
    const $window = window

    const checkIfInView = () => {
      const windowHeight = $window.innerHeight
      const windowTopPosition = $window.scrollY
      const windowBottomPosition = windowTopPosition + windowHeight

      const animationElement = document.querySelector('.animation-element')
      if (animationElement) {
        const elementHeight = animationElement.offsetHeight
        const elementTopPosition = animationElement.offsetTop
        const elementBottomPosition = elementTopPosition + elementHeight

        if (elementBottomPosition >= windowTopPosition && elementTopPosition <= windowBottomPosition) {
          setInView(true)
        } else {
          setInView(false)
        }
      }
    }

    // Add event listeners for scroll and resize
    $window.addEventListener('scroll', checkIfInView)
    $window.addEventListener('resize', checkIfInView)

    // Trigger on mount
    checkIfInView()

    // Cleanup event listeners on unmount
    return () => {
      $window.removeEventListener('scroll', checkIfInView)
      $window.removeEventListener('resize', checkIfInView)
    }
  }, [])
  //

  return (
    <section className='wrapper'>
      <div className='container'>
        <div className='row pb-2'>
          <div className='col-12'>
            <div className='center-slider'>
              <Slider {...settings}>
                {infoData.primaryBanner?.map((element, index) => {
                  return (
                    <div onClick={() => element.url && window.open(element.url)} key={index}>
                      <img src={process.env.REACT_APP_API_ROOT_URL + 'static/uploads/' + element.img} alt='slider' />
                    </div>
                  )
                })}
              </Slider>
            </div>
          </div>
        </div>
      </div>

      <div className='container py-md-4 pb-3'>
        <div className='row'>
          <div className='col-12 pb-md-4 pb-md-3 pb-2'>
            <h2 className='populargame-heading'>{filteredInfoData.availableGames?.length == 0 ? 'No games found' : 'Popular Games'}</h2>
          </div>
        </div>
        <div className='row row-cols-3 row-cols-lg-4 g-md-4 g-3 PopularGames'>
          {filteredInfoData.availableGames?.map((element, index) => {
            const img = process.env.REACT_APP_API_ROOT_URL + 'static/games/' + element.code + '.webp'
            return (
              <React.Fragment key={index}>
                <div className='col'>
                  <div className='card' onClick={() => navigate('/' + element.code)}>
                    <div className='img-box'>
                      <img src={img} alt='popular-games' />
                    </div>
                    <div className='card-body pb-0'>
                      <h5 className='card-title text-center'>{element.name}</h5>
                    </div>
                  </div>
                </div>

                {index === 0 && (
                  <div className='col'>
                    <div className='card' onClick={() => navigate('/dos')}>
                      <div className='img-box'>
                        <img src='images/dos-wallet.png' alt='popular-games' />
                      </div>
                      <div className='card-body pb-0'>
                        <h5 className='card-title text-center'>DOS Coins</h5>
                      </div>
                    </div>
                  </div>
                )}
              </React.Fragment>
            )
          })}
        </div>
      </div>

      <div className='container py-md-3 pb-3'>
        <div className='row'>
          <div className='col-12 pb-md-4 pb-md-3 pb-2 pt-2'>
            <h2 className='populargame-heading'>Rewards Center</h2>
          </div>
        </div>
        <div className='row row-cols-3 row-cols-lg-4 g-md-4 g-3 PopularGames'>
          <div className='col'>
            <div className='card' onClick={() => navigate('/')}>
              <div className='img-box'>
                <img src='images/free-diamond.png' alt='popular-games' />
              </div>
              <div className='card-body pb-0'>
                <h5 className='card-title text-center'>Giveaway Redemption</h5>
              </div>
            </div>
          </div>
        </div>

        <div className='row pt-md-4 pt-3'>
          {/* <div className='col-12 pb-md-4 pb-2 pt-3 animation-element slide-left'>
            <h2 className='populargame-heading'>DEMON OFFICIAL STORE</h2>
          </div> */}
          <div className={`col-12 pb-md-4 pb-2 pt-3 animation-element ${inView ? 'in-view' : ''} slide-left`}>
            <h2 className='populargame-heading'>DEMON OFFICIAL STORE</h2>
          </div>
          <div className='col-12'>
            {/* <h3 className='OfficialStore-heading'>Why top-up on Demon Official Store?</h3> */}
            <h3 className={`OfficialStore-heading  animation-element ${inView ? 'in-view' : ''} slide-left top-up`}>Why top-up on Demon Official Store?</h3>
            {/* <p className='official-paragraph'>
              Join the ranks of millions of passionate gamers who turn to Demon Official Store each month to revolutionize their in-game experience. As your ultimate platform for purchasing game credits and vouchers, Demon Official Store takes
              convenience, speed, and security to unprecedented heights.
            </p> */}
            <div style={{ width: '100%' }} className={`animation-element ${inView ? 'in-view' : ''} slide-left para`}>
              <p className='official-paragraph'>
                Join the ranks of millions of passionate gamers who turn to Demon Official Store each month to revolutionize their in-game experience. As your ultimate platform for purchasing game credits and vouchers, Demon Official Store takes
                convenience, speed, and security to unprecedented heights.
              </p>
            </div>
          </div>

          <div className={`row row-cols-1 row-cols-md-2 g-md-4 g-3 pt-0 mt-0 animation-element ${inView ? 'in-view' : ''} slide-left facility`}>
            <div className='col'>
              <div className='content-icon'>
                <div className='icon-box'>
                  <img src='images/fast.png' alt='icon' />
                </div>
                <div className='paragraph'>
                  <h6>Easy and Fast</h6>
                  <p>It only takes few seconds to complete a purchases on Demon Official Store.</p>
                </div>
              </div>
            </div>
            <div className='col'>
              <div className='content-icon'>
                <div className='icon-box'>
                  <img src='images/delivery.png' alt='icon' />
                </div>
                <div className='paragraph'>
                  <h6>Instant Delivery</h6>
                  <p>When you top-up on Demon Official Store, your purchases is delivered to your game account as soon as payment is completed.</p>
                </div>
              </div>
            </div>
            <div className='col'>
              <div className='content-icon'>
                <div className='icon-box'>
                  <img src='images/wallet.png' alt='icon' />
                </div>
                <div className='paragraph'>
                  <h6>Convenient Payment Methods</h6>
                  <p>To ensure your convenience, we are using safe payment methods.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ContactUs />
        <BottomMenu />
      </div>
    </section>
  )
}
