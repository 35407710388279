export const profileData = (state = {}, action) => {
    if (action.type === 'setProfileData') {
        return { ...action.payload }
    } else {
        return state
    }
}

export const infoData = (state = {}, action) => {
    if (action.type === 'setInfoData') {
        return { ...action.payload }
    } else {
        return state
    }
}

export const filteredInfoData = (state = {}, action) => {
    if (action.type === 'setFilteredInfoData') {
        return { ...action.payload }
    } else {
        return state
    }
}