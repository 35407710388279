import { setProfileData } from "../redux/actions/actions";
import Swal from "sweetalert2";

const userdetails = (navigate) => {
  return async (dispatch) => {
    try {
      const url = `${process.env.REACT_APP_API_ROOT_URL}auth/userdetails`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authToken: localStorage.getItem("authToken"),
        },
      });
      const result = await response.json();
      if (result.success) {
        if (result.data) {
          dispatch(setProfileData(result.data));
          if (navigator.userAgent === process.env.REACT_APP_NATIVE_USER_AGENT) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                event: "userInfo",
                data: result.data,
              })
            );
          }
        } else {
          localStorage.removeItem("authToken")
          navigate("/");
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "",
          text: result.msg,
          footer: "Alert by the Demon Official Store team",
          confirmButtonColor: "#008ad8",
        });
        localStorage.removeItem("authToken")
        navigate("/");
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "",
        text: "Something went wrong, please try again leter!",
        footer: "Alert by the Demon Official Store team",
        confirmButtonColor: "#008ad8",
      });
    }
  };
};

export default userdetails;
