import React, { useContext, useEffect, useState } from 'react'
import AsideBar from '../components/AsideBar'
import { Tab, Tabs } from 'react-bootstrap'
import BottomMenu from '../components/BottomMenu'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import UserContext from '../context/UserContext'
import Swal from 'sweetalert2'
import moment from 'moment'

const arr = [
  { eventKey: 'all', title: 'All Orders' },
  { eventKey: 'pending', title: 'Processing' },
  { eventKey: 'success', title: 'Completed' },
  { eventKey: 'failed', title: 'Refunded' },
]

export default function Report() {
  const infoData = useSelector((state) => state.infoData)
  const [section, setSection] = useState('all')
  const navigate = useNavigate()
  const context = useContext(UserContext)
  const { setLoader } = context
  const [query, setQuery] = useSearchParams()
  const type = query.get('type')
  const [store, setStore] = useState([])
  const [data, setData] = useState([])

  useEffect(() => {
    document.title = 'Demon Official Store - Reports'

    if (!localStorage.getItem('authToken')) navigate('/send-otp')
    if (type !== 'payment' && type !== 'purchase' && type !== 'ledger') {
      navigate('/')
    } else {
      fetchHistory('all')
    }
    // eslint-disable-next-line
  }, [type])

  useEffect(() => {
    fetchHistory(section)
  }, [section])

  const fetchHistory = async (status) => {
    try {
      setData([])
      const url = `${process.env.REACT_APP_API_ROOT_URL}${type}/history`
      setLoader(true)
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          authToken: localStorage.getItem('authToken'),
        },
        body: JSON.stringify({ status }),
      })
      const result = await response.json()
      setLoader(false)
      if (result.success) {
        setStore(result.data)
        setData(result.data)
      } else {
        Swal.fire({
          icon: 'error',
          title: '',
          text: result.msg,
          footer: 'Alert by the Demon Official Store team',
          confirmButtonColor: '#008ad8',
        })
      }
    } catch (error) {
      console.log(error)
      setLoader(false)
      Swal.fire({
        icon: 'error',
        title: '',
        text: 'Something went wrong, please try again leter!',
        footer: 'Alert by the Demon Official Store team',
        confirmButtonColor: '#008ad8',
      })
    }
  }

  const filterHandler = (e) => {
    const value = e.target.value.toLowerCase() // Convert input to lowercase
    if (value) {
      const result = store.filter(
        (element) => element.characterId.toString().toLowerCase().includes(value) // Match case-insensitively
      )
      setData(result)
    } else {
      setData(store)
    }
  }

  return (
    <section className='wrapper wrapper-asidecontent'>
      <div className='container pb-md-4 pb-3'>
        <div className='row pt-md-4 pt-3'>
          <div className='col-auto d-none d-lg-block'>
            <AsideBar />
          </div>
          <div className='col pt-md-3 pt-1 profile-wrapper order-tabs'>
            <div className='order-list'>
              <h2 className='title'>Orders</h2>
              <Tabs id='controlled-tab-example' className='d-flex justify-content-between my-md-4 my-3 flex-nowrap scrollable-tabs' activeKey={section} onSelect={(e) => setSection(e)}>
                {arr.map(({ eventKey, title }, index) => {
                  return (
                    <Tab eventKey={eventKey} title={title} key={index}>
                      <div className='row row-cols-1 g-md-3 g-2'>
                        {data.length !== 0 && (
                          <div className='col my-2'>
                            <div className='search-userid'>
                              <input type='text' className='form-control rounded-pill' placeholder='Search by User ID / Player ID' onChange={filterHandler} />
                              <button type='button'>
                                <img src='images/search.svg' alt='search' />
                              </button>
                            </div>
                          </div>
                        )}

                        {data.length === 0 ? (
                          <div className='text-center mt-4'>
                            <h5>No record found</h5>
                          </div>
                        ) : (
                          data.map((element, index) => {
                            const game = infoData.availableGames.find((ele) => ele.code === element.code)
                            const item = game?.itemList?.find((ele) => ele.id === element.itemId)

                            return (
                              type === 'purchase' &&
                              game &&
                              item && (
                                <div className='col' key={index}>
                                  <div onClick={() => navigate(`/invoice?type=purchase&orderId=${element.orderId}`)} class='card'>
                                    <div class='card-body'>
                                      <div className='d-flex w-100'>
                                        <div className='game-img'>
                                          <img src={process.env.REACT_APP_API_ROOT_URL + 'static/games/' + element.code + '.webp'} alt='game' />
                                        </div>
                                        <div className='order-detail w-100 ms-md-3 ms-2 d-flex'>
                                          <div className='game-name'>
                                            <p>{game.name}</p>
                                            <p>{item?.title}</p>
                                          </div>
                                          <div className='ms-auto'>
                                            {element?.status === 'success' && <span class='order-complete'>Completed</span>}
                                            {element?.status === 'failed' && <span className='order-refunded'>Refunded</span>}
                                            {element?.status === 'pending' && <span class='order-processing'>Processing</span>}
                                          </div>
                                        </div>
                                      </div>
                                      <div className='d-flex align-items-center'>
                                        <p class='card-subtitle'>{moment(element?.createdAt).format('DD-MM-YYYY LT')}</p>
                                        <p class='card-ammount ms-auto'>₹ {element.amount?.toFixed(2)}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            )
                          })
                        )}
                      </div>
                    </Tab>
                  )
                })}
              </Tabs>
            </div>
          </div>
        </div>

        <BottomMenu />
      </div>
    </section>
  )
}
