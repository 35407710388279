import { setInfoData, setFilteredInfoData } from "../redux/actions/actions";
import Swal from "sweetalert2";

const info = () => {
    return async (dispatch) => {
        try {
            const url = `${process.env.REACT_APP_API_ROOT_URL}info`;
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                },
            });
            const result = await response.json();
            if (result.success) {
                if (result.data) {
                    dispatch(setInfoData(result.data));
                    dispatch(setFilteredInfoData(result.data));
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "",
                        text: "Something went wrong, please try again leter!",
                        footer: "Alert by the Demon Official Store team",
                        confirmButtonColor: "#008ad8",
                    });
                }
            } else {
                Swal.fire({
                    icon: "error",
                    title: "",
                    text: result.msg,
                    footer: "Alert by the Demon Official Store team",
                    confirmButtonColor: "#008ad8",
                });
            }
        } catch (error) {
            console.log(error);
            Swal.fire({
                icon: "error",
                title: "",
                text: "Something went wrong, please try again leter!",
                footer: "Alert by the Demon Official Store team",
                confirmButtonColor: "#008ad8",
            });
        }
    };
};

export default info;
