import InfiniteScroll from 'react-infinite-scroll-component'
import React, { useContext, useEffect, useState } from 'react'
import BottomMenu from '../components/BottomMenu'
import UserContext from '../context/UserContext'
import { useNavigate } from 'react-router-dom'
import AsideBar from '../components/AsideBar'
import { useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import moment from 'moment'

export default function MyWallet() {
  const navigate = useNavigate()
  const profileData = useSelector((state) => state.profileData)
  const context = useContext(UserContext)
  const { modal, setModal, setLoader } = context
  const [page, setPage] = useState(1)
  const [totalresult, setTotalresult] = useState(0)
  const [data, setData] = useState([])

  useEffect(() => {
    document.title = 'Demon Official Store - My Wallet'
    fetchHistory()
  }, [])

  const fetchHistory = async () => {
    try {
      const url = `${process.env.REACT_APP_API_ROOT_URL}payment/history`
      setLoader(true)
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          authToken: localStorage.getItem('authToken'),
        },
      })
      const result = await response.json()
      setLoader(false)
      if (result.success) {
        setData(result.data)
      } else {
        Swal.fire({
          icon: 'error',
          title: '',
          text: result.msg,
          footer: 'Alert by the Demon Official Store team',
          confirmButtonColor: '#008ad8',
        })
      }
    } catch (error) {
      console.log(error)
      setLoader(false)
      Swal.fire({
        icon: 'error',
        title: '',
        text: 'Something went wrong, please try again leter!',
        footer: 'Alert by the Demon Official Store team',
        confirmButtonColor: '#008ad8',
      })
    }
  }

  const fetchMoreHistory = async () => {
    try {
      const url = `${process.env.REACT_APP_API_ROOT_URL}payment/history?page=${page + 1}`
      setPage(page + 1)
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          authToken: localStorage.getItem('authToken'),
        },
      })
      const result = await response.json()
      if (result.success) {
        setData(data.concat(result.data))
        setTotalresult(result.totalResult)
      } else {
        Swal.fire({
          icon: 'error',
          title: '',
          text: result.msg,
          footer: 'Alert by the TopupFactory team',
          confirmButtonColor: '#06bfad',
        })
      }
    } catch (error) {
      console.log(error)
      Swal.fire({
        icon: 'error',
        title: '',
        text: 'Something went wrong, please try again leter!',
        footer: 'Alert by the TopupFactory team',
        confirmButtonColor: '#06bfad',
      })
    }
  }

  return (
    <>
      <section className='wrapper wrapper-asidecontent'>
        <div className='container pb-md-4 pb-3'>
          <div className='row pt-md-4 pt-3'>
            <div className='col-auto d-none d-lg-block'>
              <AsideBar />
            </div>
            <div className='col'>
              <div className='row flex-column'>
                <div className='col pt-md-3 pt-1 profile-wrapper wallet'>
                  <h2 className='title'>My Wallet</h2>

                  <div className='card mt-md-4l mt-2l mt-md-4 mt-3'>
                    <div className='card-body h-100'>
                      <div className='d-flex justify-content-between align-items-center h-100'>
                        <div className='left-coindetails h-100'>
                          <h4 className='coin-title'>DOS Coins Wallet</h4>
                          <div className='d-flex align-items-center'>
                            <h4 className='coin-value me-md-2 me-1 p-0 m-0'>{profileData.wallet?.toFixed(2)}</h4>
                            <div className='coin-img '>
                              <img src='/images/dos.svg' alt='coin' />
                            </div>
                          </div>
                        </div>
                        <div className='wallet-btn'>
                          <button type='button' className='rounded-pill' onClick={() => setModal({ ...modal, addMoney: true })}>
                            <svg className='icon'>
                              <use href='#icon_plus'></use>
                            </svg>
                            <span>Add Coins</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className='mt-md-4 mt-2 transaction-title'>Transactions</p>
                </div>

                <div className='col pt-md-2 profile-wrapper order-tabs'>
                  <div className='order-list'>
                    <div className='row row-cols-1 g-md-3 g-2'>
                      <InfiniteScroll
                        dataLength={data.length}
                        next={fetchMoreHistory}
                        hasMore={data.length !== totalresult}
                        style={{ overflow: 'hidden' }}
                        loader={
                          <div className='text-center my-2'>
                            <div class='spinner-border color-highlight' role='status' style={{ color: '#008ad8' }}></div>
                          </div>
                        }
                      >
                        {data.length === 0 ? (
                          <div className='text-center mt-4'>
                            <h5>No record found</h5>
                          </div>
                        ) : (
                          data.map((element, index) => {
                            return (
                              <div className='col pt-3' key={index}>
                                <div onClick={() => navigate(`/invoice?type=payment&orderId=${element.orderId}`)} class='card'>
                                  <div class='card-body'>
                                    <div className='d-flex w-100'>
                                      <div className='game-img'>
                                        <img src='/images/dos.svg' alt='game' />
                                      </div>
                                      <div className='order-detail w-100 ms-md-3 ms-2 d-flex'>
                                        <div className='game-name'>
                                          <p>DOS Coins</p>
                                          <p>{element?.amount} Coins</p>
                                        </div>
                                        <div className='ms-auto'>
                                          {element?.status === 'success' && <span class='order-complete'>Completed</span>}
                                          {element?.status === 'failed' && <span className='order-refunded'>Failed</span>}
                                          {element?.status === 'pending' && <span class='order-processing'>Processing</span>}
                                        </div>
                                      </div>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                      <p class='card-subtitle'>{moment(element?.createdAt).format('DD-MM-YYYY LT')}</p>
                                      <p class='card-ammount ms-auto'>₹ {element.amount?.toFixed(2)}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })
                        )}
                      </InfiniteScroll>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <BottomMenu />
        </div>
      </section>
    </>
  )
}
