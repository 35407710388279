import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import UserContext from '../context/UserContext'

export default function BottomMenu() {
  const pathname = useLocation().pathname
  const profileData = useSelector((state) => state.profileData)
  const context = useContext(UserContext)
  const { showSearch } = context
  const [showBounce, setShowBounce] = useState(false)

  return (
    <>
      <div className='row'>
        <div className='col-12 mobile-footer'>
          <Link to='/' className={`btn border-0 ft-btn ${!showSearch && !showBounce && pathname === '/' && 'active'}`} onClick={() => setShowBounce(false)}>
            <div className='icon-bg active d-flex align-items-center justify-content-center mx-auto'>
              <svg className='icon'>
                <use href='#icon_home'></use>
              </svg>
            </div>
            <p>Home</p>
          </Link>
          <button className={`btn border-0 ft-btn ${showSearch && 'active'}`} onClick={() => setShowBounce(false)}>
            <div className='icon-bg d-flex align-items-center justify-content-center mx-auto'>
              <svg className='icon'>
                <use href='#icon_ft-search'></use>
              </svg>
            </div>
            <p>Search</p>
          </button>
          <Link to='/reports?type=purchase' className={`btn border-0 ft-btn ${!showSearch && !showBounce && pathname === '/reports' && 'active'}`} onClick={() => setShowBounce(false)}>
            <div className='icon-bg d-flex align-items-center justify-content-center mx-auto'>
              <svg className='icon'>
                <use href='#icon_order'></use>
              </svg>
            </div>
            <p>Orders</p>
          </Link>
          <button className={`btn border-0 ft-btn ${!showSearch && showBounce && 'active'}`} onClick={() => setShowBounce(!showBounce)}>
            <div className='icon-bg d-flex align-items-center justify-content-center mx-auto'>
              <svg className='icon'>
                <use href='#icon_chat'></use>
              </svg>
            </div>
            <p>Support</p>
          </button>
        </div>
      </div>

      {showBounce && (
        <div className='bounce'>
          <a href={`https://wa.me/918329905444/?text=${encodeURIComponent(`Name: ${profileData.name}\nNumber: ${profileData.number}\n`)}`} class='float float-whatsapp bouncel' target='_blank'>
            <svg className='icon'>
              <use href='#icon_whatsapp'></use>
            </svg>
          </a>

          <a href='https://m.me/100226139631005?source=qr_link_share' class='float float-messenger bouncel' target='_blank'>
            <svg className='icon'>
              <use href='#icon_messenger'></use>
            </svg>
          </a>

          <a href='mailto:admin@demonofficialstore.com' class='float float-email bouncel'>
            <svg className='icon'>
              <use href='#icon_email'></use>
            </svg>
          </a>
        </div>
      )}
    </>
  )
}
