import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

export default function Maintenance() {
  const profileData = useSelector((state) => state.profileData)

  return (
    <>
      <header className='section-t-space'>
        <div className='custom-container'>
          <div className='header-panel'>
            <Link className='back-btn'>
              <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth={2} strokeLinecap='round' strokeLinejoin='round' className='feather feather-arrow-left icon'>
                <line x1={19} y1={12} x2={5} y2={12} />
                <polyline points='12 19 5 12 12 5' />
              </svg>
            </Link>
            <h2>Maintenance</h2>
          </div>
        </div>
      </header>
      <section className='section-b-space'>
        <div className='custom-container'>
          <div className='empty-page'>
            <img className='img-fluid' src='assets/images/maintenance.png' alt='v1' />
            <h2 className='dark-text fw-semibold mt-3'>Scheduled Maintenance</h2>
            <h3 className='d-block fw-normal light-text text-center mt-2'>
              We are currently performing scheduled maintenance to enhance your experience on Demon Official Store. During this time, our website and services may be temporarily unavailable. We apologize for any inconvenience and appreciate your patience.
              Please check back later.
            </h3>
            <a target='_blank' href={`https://wa.me/918329905444/?text=${encodeURIComponent(`Name: ${profileData.name}\nNumber: ${profileData.number}\n`)}`} className='btn theme-btn w-100'>
              Chat With Us
            </a>
          </div>
        </div>
      </section>
    </>
  )
}
