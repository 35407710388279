import { addmoneySchema } from '../models'
import React, { useContext, useEffect } from 'react'
import { useFormik } from 'formik'
import { Modal } from 'react-bootstrap'
import Swal from 'sweetalert2'
import UserContext from '../context/UserContext'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

export default function Addmoney({ modal, setModal }) {
  const navigate = useNavigate()
  const infoData = useSelector((state) => state.infoData)
  const { payment } = infoData
  const context = useContext(UserContext)
  const { setLoader } = context

  const initialValues = {
    amount: '',
  }

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, isSubmitting } = useFormik({
    initialValues,
    validationSchema: addmoneySchema,
    onSubmit: async (values) => {
      try {
        if (payment.gateway === 'N.A') {
          setLoader(false)
          setModal({ ...modal, addMoney: false })
          Swal.fire({
            icon: 'error',
            title: '',
            text: 'Gateway down, please try again leter!',
            footer: 'Alert by the Demon Official Store team',
            confirmButtonColor: '#008ad8',
          })
        } else {
          const url = `${process.env.REACT_APP_API_ROOT_URL}payment/${payment.gateway + payment.url}`
          setLoader(true)
          setModal({ ...modal, addMoney: false })
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              authToken: localStorage.getItem('authToken'),
            },
            body: JSON.stringify(values),
          })
          const result = await response.json()
          if (result.success) {
            window.location.replace(result.data.paymentLink)
          } else {
            Swal.fire({
              icon: 'error',
              title: '',
              text: result.msg,
              footer: 'Alert by the Demon Official Store team',
              confirmButtonColor: '#008ad8',
            })
            setLoader(false)
          }
        }
      } catch (error) {
        setLoader(false)
        console.log(error)
        Swal.fire({
          icon: 'error',
          title: '',
          text: 'Something went wrong, please try again leter!',
          footer: 'Alert by the Demon Official Store team',
          confirmButtonColor: '#008ad8',
        })
      }
    },
  })

  useEffect(() => {
    document.title = 'Demon Official Store - Add Money'
    // eslint-disable-next-line
  }, [])

  return (
    <Modal centered show={modal.addMoney} backdrop='static' keyboard={false} onHide={() => setModal({ ...modal, addMoney: false })} className='addcoin-modal'>
      <Modal.Header closeButton className='border-0 py-0'></Modal.Header>
      <form className='modal-body px-md-4 px-3 mt-0 pt-0 pb-md-4' autoComplete='off' onSubmit={handleSubmit}>
        <h5 className='text-center'>Add Coins</h5>
        <p>Note: 1 DOS Coin = ₹1</p>
        <div>
          <label htmlFor='Amount' className='form-label input-label'>
            Amount
          </label>
          <input
            type='number'
            autoComplete='off'
            name='amount'
            placeholder='Your amount'
            value={values.amount}
            onChange={handleChange}
            onBlur={handleBlur}
            id='Amount'
            className={`form-control form-control-lg input-box ${!!errors.amount && touched.amount && 'is-invalid'}`}
          />
          {errors.amount && touched.amount && <div className='invalid-feedback'>{errors.amount}</div>}
        </div>
        <button type='submit' disabled={isSubmitting} className='btn btn-pay w-100 rounded-pill py-1'>
          {isSubmitting ? (
            <>
              <span className='spinner-border spinner-border-sm me-05' role='status' aria-hidden='true' />
              Loading...
            </>
          ) : (
            'Proceed to Payment'
          )}
        </button>
      </form>
    </Modal>
  )
}
