import React, { useContext, useEffect, useRef } from 'react'
import AsideBar from '../components/AsideBar'
import BottomMenu from '../components/BottomMenu'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { updateprofileSchema } from '../models'
import UserContext from '../context/UserContext'
import { useFormik } from 'formik'
import Swal from 'sweetalert2'
import userdetails from '../api/userdetails'

const initialValues = {
  name: '',
  email: '',
  gender: '',
  state: '',
}

export default function Profile() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const context = useContext(UserContext)
  const { setLoader } = context
  const profileData = useSelector((state) => state.profileData)
  const inputRef = useRef(null)

  const { values, setValues, errors, touched, handleBlur, handleChange, handleSubmit, isSubmitting } = useFormik({
    initialValues,
    validationSchema: updateprofileSchema,
    onSubmit: async (values, action) => {
      try {
        const url = `${process.env.REACT_APP_API_ROOT_URL}auth/updateprofile`
        const response = await fetch(url, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            authToken: localStorage.getItem('authToken'),
          },
          body: JSON.stringify(values),
        })
        const result = await response.json()
        action.resetForm()
        if (result.success) {
          dispatch(userdetails(navigate))
          Swal.fire({
            icon: 'success',
            title: '',
            text: result.msg,
            footer: 'Alert by the Demon Official Store team',
            confirmButtonColor: '#008ad8',
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: '',
            text: result.msg,
            footer: 'Alert by the Demon Official Store team',
            confirmButtonColor: '#008ad8',
          })
        }
      } catch (error) {
        console.log(error)
        Swal.fire({
          icon: 'error',
          title: '',
          text: 'Something went wrong, please try again leter!',
          footer: 'Alert by the Demon Official Store team',
          confirmButtonColor: '#008ad8',
        })
      }
    },
  })

  useEffect(() => {
    document.title = 'Demon Official Store - Profile'
    const { name, email } = profileData
    setValues({ ...values, name, email })
    // eslint-disable-next-line
  }, [profileData])

  return (
    <section className='wrapper wrapper-asidecontent'>
      <div className='container'>
        <div className='row pt-md-4 pt-3'>
          <div className='col-auto d-none d-lg-block'>
            <AsideBar />
          </div>
          <div className='col pt-md-3 pt-1 profile-wrapper'>
            <h2 className='title'>Profile</h2>

            <form autoComplete='off' onSubmit={handleSubmit}>
              <div className='my-md-4 my-3'>
                <label htmlFor='Name' className='form-label input-label pb-md-1 mb-md-1 p-0 m-0'>
                  Name
                </label>
                <input
                  type='text'
                  autoComplete='off'
                  name='name'
                  placeholder='Your name'
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`form-control form-control-lg input-box ${!!errors.name && touched.name && 'is-invalid'}`}
                  id='Name'
                />
                {errors.name && touched.name && <div className='invalid-feedback'>{errors.name}</div>}
              </div>

              <div className='mb-md-4 mb-3'>
                <label htmlFor='Email' className='form-label input-label pb-md-1 mb-md-1 p-0 m-0'>
                  Email
                </label>
                <input
                  type='email'
                  autoComplete='off'
                  name='email'
                  placeholder='Your email address'
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id='Email'
                  className={`form-control form-control-lg input-box ${!!errors.email && touched.email && 'is-invalid'}`}
                />
                {errors.email && touched.email && <div className='invalid-feedback'>{errors.email}</div>}
              </div>

              <div className='mb-md-4 mb-3'>
                <label htmlFor='Number' className='form-label input-label pb-md-1 mb-md-1 p-0 m-0'>
                  Number
                </label>
                <input type='text' autoComplete='off' name='number' placeholder='Your mobile number' value={profileData.number} id='Number' className='form-control form-control-lg input-box' disabled />
              </div>

              <div className='pt-2 pb-3'>
                <button type='submit' disabled={isSubmitting} className='btn btn-pay'>
                  {isSubmitting ? (
                    <>
                      <span className='spinner-border spinner-border-sm me-05' role='status' aria-hidden='true' />
                      &nbsp; Loading...
                    </>
                  ) : (
                    'Save Changes'
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>

        <BottomMenu />
      </div>
    </section>
  )
}
