import React, { useEffect, useState } from 'react'
import 'react-phone-number-input/style.css'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { updateprofileSchema } from '../../models'
import { useFormik } from 'formik'
import Swal from 'sweetalert2'
import { Link, useLocation, useNavigate } from 'react-router-dom'

export default function Register() {
  const navigate = useNavigate()
  const location = useLocation()
  const [checked, setChecked] = useState(true)
  const [modal, setModal] = useState({ terms: false })

  const initialValues = {
    name: location?.state?.name,
    email: '',
  }

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, isSubmitting } = useFormik({
    initialValues,
    validationSchema: updateprofileSchema,
    onSubmit: async (values) => {
      try {
        const url = `${process.env.REACT_APP_API_ROOT_URL}auth/updateprofile`
        const response = await fetch(url, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            authToken: localStorage.getItem('authToken'),
          },
          body: JSON.stringify(values),
        })
        const result = await response.json()
        if (result.success) {
          navigate('/')
        } else {
          Swal.fire({
            icon: 'error',
            title: '',
            text: result.msg,
            footer: 'Alert by the AllRecharge team',
            confirmButtonColor: '#008ad8',
          })
        }
      } catch (error) {
        console.log(error)
        Swal.fire({
          icon: 'error',
          title: '',
          text: 'Something went wrong, please try again leter!',
          footer: 'Alert by the AllRecharge team',
          confirmButtonColor: '#008ad8',
        })
      }
    },
  })

  useEffect(() => {
    document.title = 'Demon Official Store - Register'
    if (!location?.state?.number || !localStorage.getItem('authToken')) {
      navigate('/send-otp')
    }
  }, [])

  return (
    <>
      <div className='mainheaderFooter'>
        <Header />
      </div>
      <div className='mobile-wrapper'>
        <div className='nav-mobile'>
          <div className='container'>
            <div className='row'>
              <div className='col-12 d-flex align-items-center'>
                <Link to={-1} className='login-back' type='button' aria-label='back navigation'>
                  <svg className='icon'>
                    <use href='#icon_backarrow'></use>
                  </svg>
                </Link>

                <Link to='/' className='mobile-logo mx-auto'>
                  <img src='images/logo.png' />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <section className='wrapper-login'>
          <form className='login-form register' autoComplete='off' onSubmit={handleSubmit}>
            <h2 className='text-center'>Create Your Account</h2>

            <div className='pb-3'>
              <label htmlFor='Name' className='form-label input-label pb-md-1 mb-md-1 p-0 m-0'>
                Name
              </label>
              <input
                type='text'
                autoComplete='off'
                name='name'
                placeholder='Enter Your Name'
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                id='Name'
                className={`form-control form-control-lg input-box ${!!errors.name && touched.name && 'is-invalid'}`}
              />
              {errors.name && touched.name && <div className='invalid-feedback'>{errors.name}</div>}
            </div>

            <div className=''>
              <label htmlFor='Email' className='form-label input-label pb-md-1 mb-md-1 p-0 m-0'>
                Email
              </label>
              <input
                type='email'
                autoComplete='off'
                name='email'
                placeholder='Enter Your Email'
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                id='Email'
                className={`form-control form-control-lg input-box ${!!errors.email && touched.email && 'is-invalid'}`}
              />
              {errors.email && touched.email && <div className='invalid-feedback'>{errors.email}</div>}
            </div>

            <div className='text-center condition'>
              By clicking “Create account”, you agree to our <Link to='/register'>Terms and Conditions</Link> and <Link to='/register'>Refund Policy</Link>.
            </div>

            <button className='btn btn-pay w-100' type='submit' disabled={isSubmitting}>
              {isSubmitting ? (
                <>
                  <span className='spinner-border spinner-border-sm me-05' role='status' aria-hidden='true' />
                  Loading...
                </>
              ) : (
                'Create account'
              )}
            </button>
          </form>
        </section>

        <div className='footer-mobile'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <div className='d-flex align-items-center justify-content-between terms-row'>
                  <Link to='/terms-conditions'>Terms and Conditions</Link>
                  <Link to='/privacy-policy'>Privacy Policy</Link>
                  <Link to='/refund-policy'>Refund Policy</Link>
                </div>

                <div className='d-flex align-items-center justify-content-center copy-right'>
                  Copyright © 2024 <Link to='/'>&nbsp; Demon Official Store</Link>. All Rights Reserved.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='mainheaderFooter'>
        <Footer />
      </div>
    </>
  )
}
