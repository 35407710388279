import React, { useEffect } from 'react'
import BottomMenu from '../components/BottomMenu'
import ContactUs from '../components/ContactUs'

export default function TermsConditions() {
  useEffect(() => {
    document.title = 'Demon Official Store - Terms and Conditions'
  }, [])

  return (
    <section className='wrapper'>
      <div className='container terms-content'>
        <div className='row pt-md-4 pt-3'>
          <div className='col-12 pb-md-4 pb-md-3 pb-2 pt-3'>
            <h2 className='TermsConditions-Heading pb-md-4 pb-2'>Terms and Conditions</h2>

            <p className='terms-paragraph pb-md-4 pb-2'>
              By clicking on the field “I have read and agree with Demon Official Store Terms of Use” on the registration page, the user will be agreeing with the terms present here. Therefore, it is important to read this document carefully before
              using the services on our website. If you do not agree with these terms, then we kindly ask you not to use Demon Official Store services and send us a message letting us know which points you do not agree with, so that we can always
              improve or negotiate to provide you the best services.
            </p>

            <h3 className='Chapter-Heading pb-md-4 pb-2'>I. The Parties</h3>

            <p className='terms-paragraph pb-md-4 pb-2'>These are Parts of this Demon Official Store Terms of Use (hereinafter referred to as the "Agreement") are:</p>

            <ol className='terms-paragraph p-0 ps-4'>
              <li className='pb-md-4 pb-2'>Demon Official Store, is a company for online recharge in game and services which provides there services in India and other' counties.</li>
              <li className='pb-md-4 pb-2'>
                For the purposes of this contract, the Demon Official Store User (“User”) is the person identified when filling out the “registration” form, available on the Demon Official Store website (“Demon Official Store”). This data is personal
                and non-transferable, presuming to be true for all legal purposes and effects, the User himself being exclusively and directly responsible for such veracity. In addition, the User must have a valid email address and a password
                ("Password") that will be the personal and non-transferable identification of each User Account. If the User is under 18 years of age, registration of a User Account and adherence to this Agreement must be carried out with the
                representation or assistance of parents or guardians.
              </li>
            </ol>

            <h3 className='Chapter-Heading pb-md-4 pb-2'>II. General Clauses and Conditions</h3>

            <h4 className='sub-heading pb-md-4 pb-2'>1. Registration of User</h4>

            <ul className='terms-paragraph p-0 ps-4'>
              <li className='pb-md-4 pb-2'>
                You become a user by completing the registration of a Demon Official Store user account or by using the recharge services offered on our website. This Agreement is effective as soon as you indicate we mentioned your acceptance of the
                terms of this document.
              </li>
              <li className='pb-md-4 pb-2'>
                You may not share,reveal or otherwise allow other people to use your login passward to access Demon Official Store. Account information is personal and non-transferable. Demon Official Store is not responsible for activities carried
                out on the account by third parties, regardless of the holder's authorization.
              </li>
              <li className='pb-md-4 pb-2'>
                Any use of the Demon Official Store Account owned by you, with your login and/or password, will be deemed to have been made by you, and you will be responsible for it, for the security of your computer system and your internet
                connection.
              </li>
              <li className='pb-md-4 pb-2'>Demon Official Store is not responsible for the use of your password and Account, nor for all communications and activities on the website that result from the use of your ID and password.</li>
              <li className='pb-md-4 pb-2'>You may not sell, charge others for the right to use your Demon Official Store Account, transfer, assign, give/offer in payment, or transfer any Products you own.</li>
            </ul>

            <h4 className='sub-heading pb-md-4 pb-2'>2. Products</h4>

            <ul className='terms-paragraph p-0 ps-4'>
              <li className='pb-md-4 pb-2'>
                You acknowledge that, for any product purchased through Demon Official Store, the store will be a mere intermediary between you and software developers or publishers. You also acknowledge that some delivery delays may be due to issues
                faced by the developer and not the payment system.
              </li>
              <li className='pb-md-4 pb-2'>
                The User expressly acknowledges that Demon Official Store and its games available in its store, together with the logos, brands, insignias, symbols, distinctive signs, documentation and any other materials related to these computer
                programs, constitute, as the case may be , copyrights, trade secrets, and/or proprietary rights of Demon Official Store or its licensors, such rights being protected by national and international legislation applicable to intellectual
                property, copyrights and business and industry secrets. Under no circumstances will these assets and rights be transferred to the User, either as a result of the acquisition of a Product or in view of its use.
              </li>
              <li className='pb-md-4 pb-2'>Users are prohibited from selling products offered by Demon Official Store services. Demon Official Store is not responsible for recharges made outside its website.</li>
              <li className='pb-md-4 pb-2'>
                The User agrees that Demon Official Store is not responsible for the content created by the developer of each of the games, nor is it responsible for correcting any “bug”, anomaly, unavailability, maintenance and for content that is
                incompatible with the Users' devices. To solve possible problems related to this, the User must contact the support of the developer in question.
              </li>
              <li className='pb-md-4 pb-2'>All rights are reserved and in the event of non-compliance with these rights, legal measures and rights will be adopted by Demon Official Store, the game platform and/or the developer.</li>
            </ul>

            <h4 className='sub-heading pb-md-4 pb-2'>3. Billing and Payment of Credits</h4>

            <ul className='terms-paragraph p-0 ps-4'>
              <li className='pb-md-4 pb-2'>The User can purchase credits through the payment platform offered on our website.</li>
              <li className='pb-md-4 pb-2'>
                When opting for payment through one of the methods described above, the User will be expressing his decision to acquire the credits offered by Demon Official Store in the manner defined by this Agreement.
              </li>
              <li className='pb-md-4 pb-2'>The User declares to be aware that the exchange and/or commercialization of credits between Users involving money or real-world value are permitted.</li>
              <li className='pb-md-4 pb-2'>
                Failure to observe this clause will be considered a violation of the terms of this contract and will result in the revocation of the transaction carried out, with the consequent cancellation of credits with the platform on which it is
                made available. Any questions about the legality of the transaction should be directed to Livechat support.
              </li>
              <li className='pb-md-4 pb-2'>Demon Official Store will not be able to make available to the User, nor be responsible for credits acquired outside its website.</li>
              <li className='pb-md-4 pb-2'>Demon Official Store reserves the right to change payment methods disclosing such information on the website.</li>
              <li className='pb-md-4 pb-2'>Any and all problems with the means of payment chosen by the User must be immediately communicated to Demon Official Store through its Livechat support on Whatsapp or through its customer service email.</li>
              <li className='pb-md-4 pb-2'>If the User is under 18 years old , any purchase at Demon Official Store must be made together and in the presence of the parents and/or guardians.</li>
            </ul>

            <h4 className='sub-heading pb-md-4 pb-2'>4. Conduct, Cheat and Illegal Behavior</h4>

            <ul className='terms-paragraph p-0 ps-4'>
              <li className='pb-md-4 pb-2'>
                The User, when reloading the Demon Official Store website, is aware and agrees that, along with the platform that makes the Software available, there are inappropriate behaviors that must be observed and respected.
              </li>
              <li className='pb-md-4 pb-2'>Conduct considered irregular is defined by the Software developers and/or by the Platform to which the software is linked and may result in punishments for the User.</li>
              <li className='pb-md-4 pb-2'>
                The User agrees that, in addition to the preventive and/or corrective maintenance hypotheses set forth above, access to Demon Official Store and/or the Products may also be interrupted due to Force Majeure, Act of God and any other
                reason beyond the control and will from Demon Official Store, developers and platform.
              </li>
              <li className='pb-md-4 pb-2'>It is the User's obligation to be aware of Demon Official Store Rules of Conduct, which declares to be aware of their content and the sanctions provided therein.</li>
              <li className='pb-md-4 pb-2'>
                In case of practice of any of the Irregular Conducts, suspicion of involvement with illegal activities, in addition to applying the corresponding penalties to the User, Demon Official Store may disclose to the police, administrative
                or judicial authorities, the registration data of the User who has performed any act that imply violation of Demon Official Store's Rules of Conduct.
              </li>
            </ul>

            <h4 className='sub-heading pb-md-4 pb-2'>5. Password and Security</h4>

            <ul className='terms-paragraph p-0 ps-4'>
              <li className='pb-md-4 pb-2'>The User must keep his password confidential. If the User has reason to believe that his Password has been discovered in any way by third parties, he/she must change it immediately on the Site.</li>
              <li className='pb-md-4 pb-2'>The User agrees that he/she is responsible for all acts performed through his User Account and that Demon Official Store does not protect him against the unauthorized use of his Account.</li>
              <li className='pb-md-4 pb-2'>
                Demon Official Store will not be responsible for any personal information that the User discloses to third parties, including their Login and Password. Demon Official Store will not be responsible for any damages that the User may
                suffer due to having disclosed his personal information.
              </li>
              <li className='pb-md-4 pb-2'>For all purposes, Demon Official Store understands that all actions performed by the User Account were performed by the holder and cannot be held responsible for actions performed on User Accounts.</li>
            </ul>

            <h4 className='sub-heading pb-md-4 pb-2'>6. Ownership and Accuracy</h4>

            <ul className='terms-paragraph p-0 ps-4'>
              <li className='pb-md-4 pb-2'>It is the duty of the User to provide true, exact and correct information when registering, as well as to keep it updated.</li>
              <li className='pb-md-4 pb-2'>
                Demon Official Store reserves the right not to change the data that proves ownership of the Account, namely: registration name and date of birth. Any corrections may be made, as long as they do not imply a change in account ownership.
              </li>
            </ul>

            <h4 className='sub-heading pb-md-4 pb-2'>7. Electronic Address</h4>

            <ul className='terms-paragraph p-0 ps-4'>
              <li className='pb-md-4 pb-2'>
                It is essential for the registration of the Account and for access to the credits, that the User has a valid electronic address ("e-mail").Demon Official Store recommends that Users make sure their e-mail box is able to receive
                messages from Demon Official Store.
              </li>
              <li className='pb-md-4 pb-2'>The User agrees that Demon Official Store will use the email provided to send information about the Product, general information or any other notifications that may be necessary.</li>
            </ul>

            <h4 className='sub-heading pb-md-4 pb-2'>8. Internet Access</h4>

            <ul className='terms-paragraph p-0 ps-4'>
              <li className='pb-md-4 pb-2'>Demon Official Store does not provide access to the Internet, the User being responsible for all costs associated with his Internet connection.</li>
            </ul>

            <h4 className='sub-heading pb-md-4 pb-2'>9. Interruption of Services</h4>

            <ul className='terms-paragraph p-0 ps-4'>
              <li className='pb-md-4 pb-2'>Demon Official Store reserves the right to interrupt access to the website whenever necessary to perform any kind of maintenance, which will be duly notified to all Users in advance</li>
              <li className='pb-md-4 pb-2'>
                The User declares to be aware that the developers may interrupt access to the Software, periodically, scheduled or not, to carry out maintenance and corrections, and notifying Users is the sole responsibility of the developers and the
                platform.
              </li>
              <li className='pb-md-4 pb-2'>
                The User agrees that, in addition to the preventive and/or corrective maintenance hypotheses set forth above, access to Demon Official Store and/or the Products may also be interrupted due to Force Majeure, Act of God and any other
                reason beyond the control and will from Demon Official Store , developers and platform.
              </li>
            </ul>

            <h4 className='sub-heading pb-md-4 pb-2'>10. Termination</h4>

            <ul className='terms-paragraph p-0 ps-4'>
              <li className='pb-md-4 pb-2'>You may cancel your Demon Official Store account at any time.</li>
              <li className='pb-md-4 pb-2'>Cancellation of a Demon Official Store Account, termination of use of any Product, or request that access be terminated will not entitle you to any refund.</li>
              <li className='pb-md-4 pb-2'>Demon Official Store may cancel your Account at any time if you violate any terms of this Agreement (including any License Terms or Rules of Conduct).</li>
              <li className='pb-md-4 pb-2'>In the event that your Demon Official Store Account is terminated or canceled due to a breach of this Agreement or illegal activities, no refund will be granted, including any License fees.</li>
            </ul>

            <h4 className='sub-heading pb-md-4 pb-2'>11. General Provisions</h4>

            <ul className='terms-paragraph p-0 ps-4'>
              <li className='pb-md-4 pb-2'>
                This Agreement, which must be interpreted together with the Rules of Conduct, is the only instrument that governs the relationship between Demon Official Store and the User. Demon Official Store may change this Agreement at any time
                at its sole discretion, and said change will come into effect as of its disclosure through the official website. Every time the User agrees to this Agreement, he will be ratifying his broad and unrestricted acceptance of all its terms
                and conditions, as well as any amendments that the Agreement may receive.
              </li>
              <li className='pb-md-4 pb-2'>
                In the event that any provision of this Agreement is found to be invalid, illegal or unenforceable, the validity, legality and enforceability of the remaining provisions will in no way be affected or impaired. In this case, the
                provision considered invalid, illegal or inapplicable must be replaced by another that leads the parties to the same desired economic and legal result.
              </li>
              <li>
                The parties acknowledge and declare that the non-exercise or delay in exercising any right guaranteed to them by this Agreement or by law will be considered as mere liberality and will not constitute novation or waiver of these rights
                or the other clauses contained herein. The waiver by either Party of any of these rights shall be interpreted restrictively.
              </li>
            </ul>
          </div>
        </div>

        <ContactUs />
        <BottomMenu />
      </div>
    </section>
  )
}
